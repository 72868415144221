'use strict';

import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import FacebookIcon from 'mdi-react/FacebookIcon';
import TwitterIcon from 'mdi-react/TwitterIcon';
import LinkedinIcon from 'mdi-react/LinkedinIcon';
import InstagramIcon from 'mdi-react/InstagramIcon';
import PinterestIcon from 'mdi-react/PinterestIcon';
import MediumIcon from 'mdi-react/MediumIcon';

import Layout from '../components/Layout';
import Link from '../components/Link';

import { graphql } from 'gatsby';

const ContactContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;
`;

const Email = styled(Link)`
    font-size: 2.4rem;
    color: #ffffff;

    &:hover {
        color: ${(props) => props.theme.primaryColor};
    }
`;

const Accounts = styled.ul`
    margin-top: 3.2rem;
    padding-left: 0; /* Reset left padding which was not allowing list to be centered */

    list-style-type: none;
    text-align: center;
`;

const Account = styled.li`
    display: inline-block;
    margin-right: 1.2rem;

    svg {
        fill: #ffffff;
        width: 3.2rem;
        height: 3.2rem;
    }

    svg:hover {
        fill: ${(props) => props.theme.primaryColor};
    }

    &:last-child {
        margin-right: 0;
    }
`;

export default ({ data }) => (
    <Layout currentPage="contact" data={data}>
        <Helmet>
            <title>Contact</title>
            <meta
                name="description"
                content={data.site.siteMetadata.contactDescription}
            />
        </Helmet>

        <ContactContainer>
            <Email
                href={`mailto:${data.site.siteMetadata.email}`}
                title={data.site.siteMetadata.emailTooltip}
                rel="me"
            >
                {data.site.siteMetadata.email}
            </Email>

            <Accounts>
                <Account>
                    <Link
                        href="https://facebook.com/RishitKedia"
                        title="@RishitKedia on Facebook"
                        rel="me"
                    >
                        <FacebookIcon />
                    </Link>
                </Account>

                <Account>
                    <Link
                        href="https://twitter.com/RishitKedia"
                        title="@RishitKedia on Twitter"
                        rel="me"
                    >
                        <TwitterIcon />
                    </Link>
                </Account>

                <Account>
                    <Link
                        href="https://linkedin.com/in/RishitKedia"
                        title="@RishitKedia on LinkedIn"
                        rel="me"
                    >
                        <LinkedinIcon />
                    </Link>
                </Account>

                <Account>
                    <Link
                        href="https://instagram.com/RishitKedia"
                        title="@RishitKedia on Instagram"
                        rel="me"
                    >
                        <InstagramIcon />
                    </Link>
                </Account>

                <Account>
                    <Link
                        href="https://pinterest.com/RishitKedia"
                        title="@RishitKedia on Pinterest"
                        rel="me"
                    >
                        <PinterestIcon />
                    </Link>
                </Account>

                <Account>
                    <Link
                        href="https://medium.com/RishitKedia"
                        title="@RishitKedia on Medium"
                        rel="me"
                    >
                        <MediumIcon />
                    </Link>
                </Account>
            </Accounts>
        </ContactContainer>
    </Layout>
);

export const query = graphql`
    query ContactQuery {
        site {
            siteMetadata {
                email
                emailTooltip
            }

            ...FooterFragment
        }
    }
`;
